import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Composables
import { createVuetify } from 'vuetify'

const datalens = {
  dark: false,
  colors: {
    background: '#f5f6f8',
    'background-lighten-1': '#f2f6fc',
    surface: '#FFFFFF',
    primary: '#2563eb',
    'primary-darken-1': '#3700B3',
    secondary: '#7e88c3',
    'secondary-darken-1': '#018786',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
    test: '#f7f9fd',
    'deep-primary': '#112890',
    'basic-text': '#6B7280',
    'dark': '#111827',
    'light-surface': '#f7f6fb',
    'primary-surface': '#edf0ff'
  },
}

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  theme: {
    defaultTheme: 'datalens',
    themes: {
      datalens,
    },
  }
})