<template>
  <v-app class="app">
    <v-main style="background-color: #f9fafb;">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  data: () => ({
  }),
}
</script>

<style>
.app {
  font-family: 'Inter', sans-serif !important;
}

.v-list-item-title {
  font-family: Arial, Helvetica, sans-serif;
}

.v-list-item {
  font-family: Arial, Helvetica, sans-serif;
}
</style>