/**
 * plugins/index.js
 *
 * Automatically included in `./src/main.js`
 */

// Plugins
import vuetify from './vuetify'
import pinia from '@/stores'
import router from '@/router'
import timeago from 'vue-timeago3'
import vue3GoogleLogin from 'vue3-google-login'
import svgLoader from 'vite-svg-loader'

export function registerPlugins(app) {
  app
    .use(vuetify)
    .use(router)
    .use(pinia)
    .use(timeago)
    .use(vue3GoogleLogin, {
      clientId: import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID || ""
    })
    .use(svgLoader())
}
