import { createApp } from 'vue'
import { registerPlugins } from '@/plugins'

import axios from 'axios';
import App from './App.vue'
import router from './router';
import { useUserStore } from '@/stores/user'
import { useWorkspaceStore } from '@/stores/workspace'
import './styles/global.css';

import { globalCookiesConfig } from "vue3-cookies";

const DEFAULT_TITLE = 'Datalens - Data Discovery made Easy';

globalCookiesConfig({
    expireTimes: "7d",
    path: "/",
    domain: "",
    secure: true,
    sameSite: "None"
});

function getEnv(name) {
    return window?.configs?.[name] || null
}

const app = createApp(App)

app.config.globalProperties.$config = {
    env: getEnv('ENV') || 'dev',
    port: import.meta.env.VITE_APP_PORT || "3000",
    appName: import.meta.env.VITE_APP_PORT || "Datalens",
    state: import.meta.env.VITE_APP_STATE || "development",
    version: import.meta.env.VITE_APP_VERSION || "0.1.0",
    endpoints: import.meta.env.VITE_APP_ENDPOINTS || ['52.45.144.63', '54.81.134.249', '52.22.161.231'],
    google_oauth_client_id: import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID || "",
    stripe_public_key: import.meta.env.VITE_STRIPE_PUBLIC_KEY || "",
    navigation_drawer_width: 200,
}

app.mixin({
    data: function () {
        return {
        }
    },
    methods: {
        parseResourcePath(resource) {
            if (resource) {
                if ('original_file_name' in resource) {
                    return {
                        type: "file",
                        table: resource.original_file_name,
                        path: "Uploaded file"
                    }
                }
                const path = resource.path.split('.')
                const remain = path.slice(0, -1);
                const end = path.slice(-1)[0];
                return {
                    type: "table",
                    table: end,
                    path: remain
                }
            }
            return {}
        },
        slugify(str) {
            return String(str)
                .normalize('NFKD')
                .replace(/[\u0300-\u036f]/g, '')
                .trim()
                .toLowerCase()
                .replace('_', '-')
                .replace(/[^a-z0-9 -]/g, '')
                .replace(/\s+/g, '-')
                .replace(/-+/g, '-');
        },
        prettyFormat(str) {
            const stringWithSpaces = str.replace(/_/g, ' ');
            const prettyFormat = stringWithSpaces.charAt(0).toUpperCase() + stringWithSpaces.slice(1);

            return prettyFormat;
        },
        getDatalensEndpoints() {
            return app.config.globalProperties.$config['endpoints']
        },
        toTitleCase(s) {
            return s.replace(/^_*(.)|_+(.)/g, (s, c, d) => (c ? c.toUpperCase() : ' ' + d.toUpperCase()))
        },
        getDrawerWidth() {
            return app.config.globalProperties.$config['navigation_drawer_width']
        }
    }
})

registerPlugins(app)

app.mount('#app')

const authenticated = axios.create({
    baseURL: `https://${getEnv('BACKEND_HOST') || "api.dev.getdatalens.com"}`
});

authenticated.defaults.headers.common['Accept'] = 'application/json';
authenticated.defaults.headers.common['Content-Type'] = 'application/json';

authenticated.interceptors.response.use(function (response) {
    return response
}, error => {
    if (error?.response?.status === 401) {
        const userStore = useUserStore()
        userStore.logout()
        router.push('/login');
        return Promise.reject(error);
    }
    return Promise.reject(error);
})

router.beforeEach(async (to, from, next) => {
    const userStore = useUserStore()
    const workspaceStore = useWorkspaceStore()
    document.title = to.meta.title || DEFAULT_TITLE;

    // Checks if user open new tab and already has a stored token but is not authenticated
    if (userStore.token && !userStore.isAuthenticated()) {
        try {
            await userStore.fetchUser()
            await workspaceStore.fetchWorkspaces()
        }
        catch (error) {
            userStore.logout()
            router.push('/login');
        }
    }
    // Checks if user tries to access private page but is not authenticated
    if (to.meta.access == "private" && !userStore.isAuthenticated()) {
        userStore.logout()
        router.push('/login');
        return Promise.reject("Unauthorized");
        // Checks if user tries to public page but is authenticated
    } else if (to.meta.access == "public" && userStore.isAuthenticated()) {
        router.push('/');
    }
    next()
});

axios.interceptors.request.use((config) => {
    return config;
});

export { authenticated };